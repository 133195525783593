import React, { useState, useContext } from 'react';
import { BASE_REGION_URL } from 'utils/constants';
import { gql, useQuery } from '@apollo/client';
import { AuthDataContext } from '../AuthDataContext/AuthDataContext';

export const RegionDataContext = React.createContext();

export const RegionDataProvider = ({ children }) => {
  const { region } = useContext(AuthDataContext);
  const [regionUrl, setRegionUrl] = useState(BASE_REGION_URL);
  const [regionCurrency, setRegionCurrency] = useState('USD');
  const [regionIso, setRegionIso] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [platformTerms, setPlatformTerms] = useState(null);
  const [productLink, setProductLink] = useState(null);
  const [sellerLink, setSellerLink] = useState(null);

  const processData = (data) => {
    setRegionUrl(data.getRegions[0]?.baseUrl);
    setRegionCurrency(data.getRegions[0]?.currency);
    setRegionIso(data.getRegions[0]?.iso);
    setPlatform(data.getRegions[0]?.platform);
    if (data.getRegions[0]?.platform === 'amazon') {
      setProductLink(`${data.getRegions[0]?.baseUrl}/dp/`);
      setSellerLink(`${data.getRegions[0]?.baseUrl}/sp?_encoding=UTF8&seller=`);
    } else {
      setProductLink(`${data.getRegions[0]?.baseUrl}/ip/`);
      setSellerLink(`${data.getRegions[0]?.baseUrl}/seller/`)
    }
  };

  const GET_REGION = gql`
    query GetRegionURL($countryCode: String) {
      getRegions(countryCode: $countryCode) {
        baseUrl
        currency
        iso
        platform
      }
    }
  `;

  useQuery(GET_REGION, {
    fetchPolicy: 'network-only',
    skip: !region,
    variables: {
      countryCode: region,
    },
    onCompleted: (result) => processData(result),
    onError: (e) => {
      console.log(e);
    },
  });

  const processTerms = (termsData) => {
    const terms = {};
    termsData.getPlatformSchema.forEach((e) => (terms[e?.key] = e?.value));
    setPlatformTerms(terms);
  };

  const GET_PLATFORMTERMS_QUERY = gql`
    query GetPlatformSchema($platform: String) {
      getPlatformSchema(platform: $platform) {
        platform
        key
        value
      }
    }
  `;

  useQuery(GET_PLATFORMTERMS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !platform,
    variables: {
      platform,
    },
    onCompleted: (result) => processTerms(result),
    onError: (e) => {
      console.log(e);
    },
  });

  return (
    <RegionDataContext.Provider
      value={{
        regionUrl,
        regionCurrency,
        regionIso,
        platform,
        platformTerms,
        productLink,
        sellerLink
      }}
    >
      {children}
    </RegionDataContext.Provider>
  );
};
