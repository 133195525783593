import React, { useEffect, useState, useContext } from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Typography,
  Box,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  Content,
  Top,
  Scrollable,
  Loading,
  Header,
  ConfirmationDialog,
  LoadingButton,
  NonScrollable,
} from 'components';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { foundKeywords } from 'utils/strings';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useQuery, useMutation, gql } from '@apollo/client';
import EditActionFieldsDialog from 'pages/Common/EditActionFieldsDialog';
import InfiniteScroll from 'react-infinite-scroller';
import EditManualTestBuyDialog from './EditManualTestBuyDialog';
import ChargeManualTestBuyDialog from './ChargeManualTestBuyDialog';
import TestBuyItem from './TestBuyItem';
import TestBuyDetail from './TestBuyDetail';

const TestBuys = () => {
  const [loading, setLoading] = useState(true);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [orders, setOrders] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const [filtered, setFiltered] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const noFilter = { id: 0, name: 'All' };
  const [selected, setSelected] = useState(0);
  const [deleteConfirmationTestBuyId, setDeleteConfirmationTestBuyId] = useState(null);
  const [deletingTestBuy, setDeletingTestBuy] = useState(false);
  const [editOrder, setEditOrder] = useState(null);
  const [editManualOrder, setEditManualOrder] = useState(null);
  const [chargeManualOrder, setChargeManualOrder] = useState(null);
  const { isAbilityAvailableInRegion, region, account } = useContext(AuthDataContext);
  const [selectedTestBuy, setSelectedTestBuy] = useState(null);
  const [search, setSearch] = useState(null);
  const scrollPageLength = 7;
  const [limit, setLimit] = useState(scrollPageLength);
  const theme = useTheme();

  const loadMore = () => {
    if (limit < filtered.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > filtered.length) {
        newLimit = filtered.length;
      }
      setLimit(newLimit);
    }
  };

  const onSearch = (keyword) => {
    const filteredOrders = orders.filter((order) => foundKeywords(order.objString, keyword));
    setFiltered(filteredOrders);
    setSearch(keyword);
    setSelectedTestBuy(filteredOrders.length > 0 ? filteredOrders[0] : null);
  };

  const onFilter = (status) => {
    setSelected(status);
    setSearch(null);
    setLoading(true);
    let filteredOrders = orders;
    if (status !== 0) {
      filteredOrders = orders.filter((order) => order.orderStatusId === status);
    }
    setFiltered(filteredOrders);
    setSelectedTestBuy(filteredOrders.length > 0 ? filteredOrders[0] : null);
    setLoading(false);
  };

  const GET_ACTIONS_QUERY = gql`
    query GetTestBuys {
      getAmazonOrders {
        id
        externalId
        asin
        productTitle
        variantTitle
        sellerId
        sellerName
        businessName
        sellerAddress
        tracking
        retailerTrackingNumber
        productPrice
        shippingPrice
        tax
        currency
        creditsUsed
        charge
        shippingAddress
        orderDate
        orderStatusId
        orderStatusName
        orderStatusError
        deliveryDate
        productImage
        offerId
        amazonOrderId
        actionId
        userId
        userFirstName
        userLastName
        userEmail
        isPaid
        fields {
          id
          idString
          customFieldId
          customValueId
          name
          description
          type
          area
          stringOptions
          valueInt
          valueString
          valueDate
          valueStrings
          valueAddress {
            addressLine1
            addressLine2
            city
            state
            zip
          }
          multiple
          timestamp
          extraTextLabel
          extraText
          userEmail
          userFirstName
          userLastName
          updatedDate
          createdDate
          s3filename
        }
      }
      getAmazonOrderStatuses {
        id
        name
      }
    }
  `;
  const { data, refetch } = useQuery(GET_ACTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processData = (dataToProcess) => {
    let selectedId = null;
    if (selectedTestBuy) {
      selectedId = selectedTestBuy.id;
    }
    const ordersWithString = dataToProcess.getAmazonOrders.map((r) => ({
      ...r,
      objString: [
        r.productTitle,
        r.asin,
        r.sellerId,
        r.sellerName,
        r.sellerAddress,
        r.orderStatusName,
        r.id, 
        r.tracking,
        r.retailerTrackingNumber,
        r.amazonOrderId,
        JSON.stringify(r.shippingAddress),
      ].join(' '),
    }));
    setOrders(ordersWithString);
    setFiltered(ordersWithString);
    const newResults = [noFilter, ...dataToProcess.getAmazonOrderStatuses];
    setStatuses(newResults);
    if (loading) {
      setSelectedTestBuy(ordersWithString.length > 0 ? ordersWithString[0] : null);
    } else if (selectedId) {
      for (let i = 0; i < ordersWithString.length; i += 1) {
        if (ordersWithString[i].id === selectedId) {
          setSelectedTestBuy(ordersWithString[i]);
          break;
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  const REMOVE_TEST_BUY_MUTATION = gql`
    mutation RemoveTestBuy($id: Int!) {
      deleteAmazonOrder(id: $id)
    }
  `;
  const [removeTestBuyMutation] = useMutation(REMOVE_TEST_BUY_MUTATION, {});

  const removeTestBuy = async (id) => {
    setDeletingTestBuy(true);
    removeTestBuyMutation({
      variables: { id },
      onCompleted: () => {
        setDeletingTestBuy(false);
        setDeleteConfirmationTestBuyId(null);
        setLoading(true);
        refetch();
      },
      onError: (e) => {
        setDeletingTestBuy(false);
        setDeleteConfirmationTestBuyId(null);
        setToast({ type: 'error', message: e.message });
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'AmazonOrder' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const saveCsv = async () => {
    setLoadingCsv(true);
    const options = {
      report: 'test_buy_history',
      accountId: account.id,
      regionId: region,
    };
    if (selected > 0 || search) {
      options.subset = filtered.map(o => o.id);
    }
    try {
      const { tokens } = await fetchAuthSession();
      const token = tokens?.idToken?.toString();
      const result = await axios.post(
        `${process.env.REACT_APP_REPORTS_URL}/report`,
        options,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: token,
          },
          responseType: 'blob',
          timeout: 60000,
        },
      );

      const filename = decodeURIComponent(result.headers.filename);
      const href = URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoadingCsv(false);
    } catch (err) {
      setLoadingCsv(false);
      setToast({ type: 'error', message: err.message });
      console.error(err.message);
    }
  };

  const handleEditDone = (success) => {
    setEditOrder(null);
    if (success) {
      refetch();
    }
  }

  const handleEditManualDone = (success) => {
    setEditManualOrder(null);
    if (success) {
      refetch();
    }
  }

  const handleChargeManualDone = (success) => {
    setChargeManualOrder(null);
    if (success) {
      refetch();
    }
  }

  if (!isAbilityAvailableInRegion('makeTestBuy')) {
    return (
      <Content transparent>
        <Top sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <Header title="Test Buys" />
        </Top>
        <Box sx={{ overflow: 'hidden', display: 'flex', height: '100%', justifyContent: 'center' }}>
          <NonScrollable
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              overflow: 'hidden',
              width: '100%',
              fontStyle: 'italic',
              fontSize: '14px',
              textAlign: 'center',
              p: 3,
            }}
          >
            This feature is not available in this region
          </NonScrollable>
        </Box>
      </Content>
    );
  }

  return (
    <>
      <Content transparent>
        <Top>
          <Header title="Test Buys" showCredits>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
              <Tabs
                color="primary"
                value={selected}
                onChange={(_event, status) => onFilter(status)}
              >
                {statuses.map((status) => (
                  <Tab
                    key={status.name}
                    sx={{ textTransform: 'capitalize' }}
                    label={status.name}
                    data-cy={status.name}
                  />
                ))}
              </Tabs>
              {!loading && (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'stretch' }}>
                  <TextField
                    sx={{ width: '30ch' }}
                    value={search ?? ''}
                    onChange={(event) => onSearch(event.target.value)}
                    multiline
                    placeholder="Search"
                    data-cy="test_buy_search"
                    InputProps={{
                      sx: {
                        minHeight: '100%',
                        pl: 1,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fill={theme.palette.greys.silver} />
                        </InputAdornment>
                      ),
                      endAdornment: search ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setSearch('');
                              onFilter(selected);
                            }}
                            size="large"
                          >
                            <ClearIcon fill={theme.palette.greys.silver} />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        ''
                      ),
                    }}
                  />
                  <LoadingButton
                    loading={loadingCsv}
                    sx={{ borderColor: 'greys.lightGrey' }}
                    variant="outlined"
                    disabled={loading || loadingCsv}
                    startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                    onClick={saveCsv}
                    data-cy="test_buy_download"
                  >
                    Download
                  </LoadingButton>
                </Box>
              )}
            </Box>
          </Header>
        </Top>
        <Scrollable transparent transparentPadding={2} sx={{ height: '100%' }}>
          {loading && <Loading />}
          {!loading && (
            <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
              <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ overflow: 'scroll' }}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore
                    loader={limit < filtered.length && <Loading key={0} />}
                    useWindow={false}
                  >
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {filtered &&
                            filtered.length > 0 &&
                            filtered.slice(0, limit).map((o) => (
                              <TableRow key={o.id} sx={{ borderRight: 'none' }}>
                                <TableCell
                                  sx={{
                                    margin: 0,
                                    cursor: 'pointer',
                                    bgcolor: selectedTestBuy?.id === o.id ? 'greys.white' : 'transparent',
                                  }}
                                >
                                  <TestBuyItem
                                    key={o.id}
                                    testBuy={o}
                                    setSelectedTestBuy={setSelectedTestBuy}
                                    onRefetch={refetch}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {filtered.length === 0 && (
                      <Box sx={{ ml: '4px', fontSize: '14px' }} data-cy="no_results">No Results</Box>
                    )}
                  </InfiniteScroll>
                </Box>
              </Box>
              <Box sx={{ width: '60%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                {selectedTestBuy && (
                  <TestBuyDetail
                    order={selectedTestBuy}
                    onEdit={setEditOrder}
                    onEditManual={setEditManualOrder}
                    onChargeManual={setChargeManualOrder}
                    onRefetch={refetch}
                    onDelete={(tb) => setDeleteConfirmationTestBuyId(tb.id)}
                  />
                )}
              </Box>
            </Box>
          )}
          {filtered.length === 0 && !loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                mt: 6,
                color: 'greys.silver',
              }}
            >
              <Typography variant="h4">No Results</Typography>
            </Box>
          )}
        </Scrollable>
        {editOrder && (
          <EditActionFieldsDialog
            actionId={editOrder.actionId}
            onClose={handleEditDone}
            areas={['testBuy', 'action']}
          />
        )}
        {editManualOrder && (
          <EditManualTestBuyDialog
            onClose={handleEditManualDone}
            areas={['testBuyManual']}
            order={editManualOrder}
          />
        )}
        {chargeManualOrder && (
          <ChargeManualTestBuyDialog
            onClose={handleChargeManualDone}
            areas={['testBuyManual']}
            order={chargeManualOrder}
          />
        )}
        <ConfirmationDialog
          open={deleteConfirmationTestBuyId !== null}
          title="Delete Test Buy"
          message="Are you sure you wish to delete this Test Buy?"
          busy={deletingTestBuy}
          okTitle="Delete"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              removeTestBuy(deleteConfirmationTestBuyId);
            } else {
              setDeleteConfirmationTestBuyId(null);
            }
          }}
        />
      </Content>
    </>
  );
};

export default TestBuys;
