import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton, Typography } from '@mui/material/';
import { AuthDataContext, ToastDataContext, RegionDataContext } from 'contexts';
import {
  LOCAL_STORAGE_AMAZON_REGION_SUFFIX,
  ALL_REGIONS,
  USA_REGION,
  LOCAL_STORAGE_ACCOUNTID_SUFFIX,
} from 'utils/constants';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import { createLocalStorageKey } from 'utils/misc';
import { capitalizeFirstLetter } from 'utils/strings';
import { useQuery, gql } from '@apollo/client';
import PlatformLogo from 'pages/Common/PlatformLogo';
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from 'components/Menu/Dropdown';
import { useHistory, useLocation } from 'react-router-dom';

const PlatformRegionDropdown = ({ showAllRegionOption = false, size = 16 }) => {
  const initialRegion = USA_REGION;
  const { user, account } = useContext(AuthDataContext);
  const { platformTerms, regionIso } = useContext(RegionDataContext);
  const { setToast } = useContext(ToastDataContext);
  const history = useHistory();
  const location = useLocation();
  const accountKey = createLocalStorageKey({
    environment: process.env.NODE_ENV,
    userId: user?.profile?.userId,
    suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX,
  });
  const accountId = parseInt(localStorage.getItem(accountKey), 10);
  const tenant = account?.tenant ?? null;
  const regionKey = createLocalStorageKey({
    environment: process.env.NODE_ENV,
    userId: user?.profile?.userId,
    suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX,
    accountId,
  });
  const [regions, setRegions] = useState(null);
  const [asinCounts, setAsinCounts] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(
    localStorage.getItem(regionKey) ?? initialRegion,
  );
  const [loading, setLoading] = useState(true);
  const [platforms, setPlatforms] = useState([]);

  const GET_INFO_QUERY = gql`
    query GetInfo {
      getRegions {
        baseUrl
        id
        currency
        iso
        name
        nativeName
        asinCount
        platform
      }
    }
  `;

  const { data } = useQuery(GET_INFO_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (data?.getRegions) {
      const dict = {};
      const countArray = data.getRegions;
      for (let i = 0; i < countArray.length; i += 1) {
        dict[countArray[i].id] = countArray[i].asinCount;
      }
      setAsinCounts(dict);
    }
  }, [data]);

  const handleMenuSelect = (isoCode) => {
    if (isoCode !== selectedRegion) {
      setSelectedRegion(isoCode);
      localStorage.setItem(regionKey, isoCode);
      history.push(location.pathname); // When switching regions, take off any query parameters on the URL
      window.location.reload();
    }
  };

  const getRegionsForUser = () => {
    const isAllRegionsSelected = (list) => {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].id === ALL_REGIONS) {
          return true;
        }
      }
      return false;
    };
    const hasRegionAvailableInTenant = (r) =>
      account.tenant?.configuredRegions?.find((i) => i.id === r.id) !== undefined;
    const hasRegionAvailableInAccount = (r) =>
      account?.configuredRegions?.find((i) => i.id === r.id) !== undefined;
    const hasRegionConfiguredInProfile = (r) =>
      user?.profile?.configuredRegions?.find((i) => i.id === r.id) !== undefined;

    const regionsToDisplay = [];
    for (let i = 0; i < account?.tenant.allRegions.length; i += 1) {
      const region = account?.tenant.allRegions[i];
      if (!(!showAllRegionOption && region.id === ALL_REGIONS)) {
        const found =
          (hasRegionConfiguredInProfile(region) ||
            isAllRegionsSelected(user?.profile?.configuredRegions)) &&
          ((hasRegionAvailableInAccount(region) &&
            (hasRegionAvailableInTenant(region) ||
              isAllRegionsSelected(account?.tenant?.configuredRegions))) ||
            (isAllRegionsSelected(account?.configuredRegions) &&
              (hasRegionAvailableInTenant(region) ||
                isAllRegionsSelected(account?.tenant?.configuredRegions))));
        regionsToDisplay.push({ region, enabled: found !== false });
      }
    }
    regionsToDisplay.sort((a, b) => {
      if (a.enabled === b.enabled) {
        return a.region.name.localeCompare(b.region.name);
      }
      return (b.enabled ? 1 : 0) - (a.enabled ? 1 : 0);
    });
    return regionsToDisplay;
  };

  const setUpRegions = () => {
    const regionsToDisplay = getRegionsForUser();
    const uniquePlatforms = [
      ...new Set(
        regionsToDisplay.filter((r) => r.region.platform !== null).map((a) => a.region.platform).sort((a, b) => a.localeCompare(b)),
      ),
    ];
    setPlatforms(uniquePlatforms);
    setRegions(regionsToDisplay);

    const enabledRegions = regionsToDisplay.filter((r) => r.enabled);
    const storedRegion = localStorage.getItem(regionKey);
    let found = false;
    for (let i = 0; i < enabledRegions.length; i += 1) {
      if (enabledRegions[i].region.id === storedRegion) {
        found = true;
      }
    }
    if (!found && enabledRegions.length > 0) {
      const hasDefaultInitialRegion = () => enabledRegions.find((i) => i.region.id === USA_REGION);
      // If the user has a region saved that is no longer available, set the region to the first available one for the user
      if (hasDefaultInitialRegion()) {
        localStorage.setItem(regionKey, USA_REGION);
        setSelectedRegion(USA_REGION);
      } else {
        localStorage.setItem(regionKey, enabledRegions[0].region.id);
        setSelectedRegion(enabledRegions[0].region.id);
      }
      window.location.reload();
    } else if (!found && enabledRegions.length === 0 && initialRegion !== selectedRegion) {
      // If the user has no region available, show the default US region
      localStorage.setItem(regionKey, initialRegion);
      setSelectedRegion(initialRegion);
      window.location.reload();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (typeof account === 'object' && typeof tenant === 'object') {
      let storedRegion = localStorage.getItem(regionKey);
      if (!storedRegion) {
        localStorage.setItem(regionKey, initialRegion);
        storedRegion = initialRegion;
        setSelectedRegion(storedRegion);
      }
      setUpRegions();
    }
  }, [account, tenant]);

  return (
    <Box sx={{ minWidth: '40px', maxWidth: '40px' }}>
      {loading && selectedRegion && (
        <IconButton disabled>
          {selectedRegion !== ALL_REGIONS && (
            <img
              style={{ filter: 'drop-shadow(0px 0px 0px black)', transform: 'translateZ(0)' }}
              alt={`${selectedRegion} flag`}
              src={`https://flagsapi.com/${regionIso}/flat/${size}.png`}
            />
          )}
          {selectedRegion === ALL_REGIONS && <Typography>ALL</Typography>}
        </IconButton>
      )}
      {!loading && (
        <div>
          <Dropdown
            trigger={
              <IconButton
                sx={{
                  borderRadius: 1,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <PlatformLogo size={30} />
                  {selectedRegion !== ALL_REGIONS && (
                    <img
                      style={{
                        filter: 'drop-shadow(0px 0px 0px black)',
                        transform: 'translateZ(0)',
                      }}
                      alt={`${selectedRegion} flag`}
                      src={`https://flagsapi.com/${regionIso}/flat/${size}.png`}
                      data-cy="region_button"
                    />
                  )}
                  {selectedRegion === ALL_REGIONS && <Typography>ALL</Typography>}
                  <CaretDown />
                </Box>
              </IconButton>
            }
            menu={platforms.map((p) => (
              <DropdownNestedMenuItem
                label={capitalizeFirstLetter(p)}
                data-cy="platform_region_selection"
                menu={regions
                  .filter((i) => i.region.platform === p)
                  .map((r) => (
                    <DropdownMenuItem
                      onClick={() => handleMenuSelect(r.region.id)}
                      disabled={!r.enabled}
                      data-cy={`region_${r.region.id}`}
                      sx={{ py: '2px' }}
                    >
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', minHeight: '36px' }}>
                        {r.id !== ALL_REGIONS && (
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <img
                              style={{
                                minWidth: '24px',
                                minHeight: '24px',
                                filter: 'drop-shadow(0px 0px 0px black)',
                                transform: 'translateZ(0)',
                              }}
                              alt={`${r.region.id} flag`}
                              src={`https://flagsapi.com/${r.region.iso}/flat/24.png`}
                            />
                          </Box>
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                          <Box sx={{ fontSize: '14px', lineHeight: '16px'}}>{r.region.name}</Box>
                          <Box sx={{ fontSize: '10px', color: 'greys.grey', lineHeight: '12px' }}>
                            {asinCounts[r.region.id]
                              ? `${asinCounts[r.region.id].toLocaleString('en-US', {
                                maximumFractionDigits: 0,
                              })} ${asinCounts[r.region.id] === 1 ? platformTerms.genericTerm : platformTerms.genericTermPlural}`
                              : ''}
                          </Box>
                        </Box>
                      </Box>
                    </DropdownMenuItem>
                  ))}
              />
            ))}
          />
        </div>
      )}
    </Box>
  );
};

export default PlatformRegionDropdown;
