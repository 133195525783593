import React, { useEffect, useContext, useState, useRef } from 'react';
import { fetchAuthSession, signOut, getCurrentUser } from '@aws-amplify/auth';
import { AuthDataContext, ToastDataContext } from 'contexts';
import userService from 'services/user-service';
import accountService from 'services/account-service';
import { useMutation, gql } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { LOCAL_STORAGE_ACCOUNTID_SUFFIX, LOCAL_STORAGE_AMAZON_REGION_SUFFIX, USA_REGION } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const AuthWrapper = ({ children }) => {
  const { user, account, setUser, setAccount } = useContext(AuthDataContext);
  const { setToast } = useContext(ToastDataContext);
  const [active, setActive] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const passedAccountId = query.get('aid') ? decodeURIComponent(query.get('aid')) : null;
  const passedRegionId = query.get('rid') ? decodeURIComponent(query.get('rid')) : null;

  const PING_ACTIVITY_MUTATION = gql`
    mutation PingActivity($url: String!) {
      pingActivity(url: $url)
    }
  `;

  const [pingActivity] = useMutation(PING_ACTIVITY_MUTATION, {
    onError: (e) => {
      console.error('Ping Activity Error: ', e.message);
    },
  });

  const handleInterval = async () => {
    // Track activity
    if (user?.profile?.userId) {
      if (!document.hidden && active) {
        pingActivity({ variables: { url: location.pathname } });
      }
    }
  }

  useInterval(handleInterval, 10000);

  useEffect(() => {
    window.addEventListener('blur', () => {
      setActive(false);
    });
    window.addEventListener('focus', () => {
      setActive(true);
    });
  }, []);

  useEffect(() => {
    if (passedAccountId && account && user && user.profile) {
      const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: user.profile.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId: parseInt(passedAccountId, 10) });
      if (parseInt(passedAccountId, 10) !== account.id || (passedRegionId && passedRegionId !== localStorage.getItem(regionKey))) {
        const accounts = user?.profile?.availableAccounts ?? [];
        let found = null;
        for (let i = 0; i < accounts.length; i += 1) {
          const a = accounts[i];
          if (a.accountId === parseInt(passedAccountId, 10)) {
            found = a;
          }
        }
        if (found) {
          accountService.getAccountById(found.accountId).then(async (acct) => {
            if (account?.id !== acct?.id || (passedRegionId && passedRegionId !== localStorage.getItem(regionKey))) {
              setAccount(acct);
              const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: user.profile.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
              localStorage.setItem(accountKey, acct.id);
              const r = passedRegionId ?? localStorage.getItem(regionKey);
              if (!r) {
                localStorage.setItem(regionKey, USA_REGION);
              } else if (passedRegionId) {
                localStorage.setItem(regionKey, passedRegionId);
              }
              window.location.reload();
            }
          });
        } 
      }
    }
  }, [location, account]);

  useEffect(async () => {
    let mounted = true;
    if (mounted) {
      try {
        const { tokens } = await fetchAuthSession();
        await getCurrentUser();
        const token = tokens?.idToken;
        const payload = token?.payload;
        if (payload && payload["custom:UserID"]) {
          userService.getUser().then(async (profile) => {
            setUser({ profile });
            userService.updateUserLoggedIn();
            const userAccounts = profile ? profile.availableAccounts : [];
            if (userAccounts.length > 0) {
              const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: profile.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
              let accountId = parseInt(localStorage.getItem(accountKey), 10);
              let found = false;
              for (let i = 0; i < userAccounts.length; i += 1) {
                const userAccount = userAccounts[i];
                if (userAccount.accountId === accountId) {
                  found = true;
                }
              }
              if (!found) {
                // Pick the first account in their list, if in the corner case, that there last account is no longer active
                accountId = userAccounts[0].accountId;
              }
              const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: profile.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId });
              const r = localStorage.getItem(regionKey);
              if (!r) {
                localStorage.setItem(regionKey, USA_REGION);
              }

              accountService.getAccountById(accountId).then(async (acct) => {
                setAccount(acct);
              });
            } else {
              setToast({ type: 'error', message: 'This user does not have access to any accounts' });
              await signOut();
              window.location.href = '/login';
            }
          }).catch(async err => {
            await signOut();
            console.error(`AuthWrapper: ${err}`);
            window.location.href = '/login';
          });
        }
      } catch (err) {
        if (err.name !== 'NotAuthorizedException') {
          if (err?.name !== 'UserUnAuthenticatedException') {
            setToast({ type: 'error', message: 'Sorry an error has occurred' });
            console.error('AuthWrapper authentication error', err);
          } else if (err?.name === 'UserUnAuthenticatedException') {
            let url = `/login?redirect=${encodeURIComponent(location.pathname+location.search)}`;
            if (passedAccountId && passedAccountId.length > 0 ) {
              url += `&aid=${passedAccountId}`;
            }
            history.push(url);
          }
        }
        setUser({});
      }
    }
    return () => {
      mounted = false;
    };
  }, []);
  
  return <>{children}</>;
};

export default AuthWrapper;
