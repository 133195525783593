import { secureGraphQLFetch } from './fetch-service';

const asinFragment = `
  id
  asin
  name
  price
  harvested
  createdDate
  currentScore {
    score
    harvestDate
  }
  offers {
    offerId
    sellerId
    sellerName
  }`;

const categoryFragment = `
  id
  name
  currentScore {
    score
    harvestDate
  }
  asins {
    ${asinFragment}
  }`;

const getAccountById = (id) => {
  const query = `query {
          getAccount(id: ${id}) {
             id
             name
             createdDate
             expirationDate
             contactName
             contactPhone
             contactEmail
             stripeCustomerId
             brandRegistrationMember
             amazonSeller
             ownerOrAgent
             asinCount
             fetchInventory
             customPlan
             tenantId
             termsAndConditionsViolations
             permittedFeatures {
              featureName
              regionId
             }
             permittedDomains {
              domain
             }
             featureAvailability {
              featureName
              regionId
             }
             abilityAvailability {
              abilityName
              regionId
             }
             configuredRegions {
              id
              iso
              name
              nativeName
              platform
             }
             logo
             demo
             highlyRestricted
             users {
              id
              userId
              cognitoId
              createdDate
              lastLoggedIn
              firstName
              lastName
              email
            }
            tenant {
              id
              tenant
              title
              buttonColor
              navColor
              loginLogo
              navLogo
              federatedDomain
              federatedProvider
              federatedShortName
              configuredFeatures {
                id
                name
                description
              }
              configuredRegions {
                id
                iso
                name
                nativeName
                platform
              }
              allRegions {
                baseUrl
                id
                currency
                iso
                name
                nativeName
                platform
              }
            }
          }
        }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getAccount);
};

const getAsinNumbersForAccount = async () => {
  const query = `query {
    getAsinsForAccount {
      asin
      categoryId
    }
  }`;
  return secureGraphQLFetch({ query }).then((result) =>
    result?.getAsinsForAccount?.map((e) => e?.asin),
  );
};

const getCategories = () => {
  const query = `query {
          getCategories {
            id
            name
            currentScore {
              score
              harvestDate      
            }
          }
        }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getCategories);
};

const getCategory = ({ id }) => {
  const query = `query {
          getCategory(id: ${id}) {
            ${categoryFragment}
          }
        }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getCategory);
};

const addCategory = ({ name, asins, addVariants, tier }) => {
  // Clean up the asin array, since GraphQL doesn't like property keys
  // in quotes, which JSON.stringify will add
  const cleanAsins = [];
  for (let index = 0; index < asins.length; index += 1) {
    const asin = asins[index];
    if (asin.price) {
      cleanAsins.push(`{asin:${JSON.stringify(asin.asin)},price:${asin.price}}`);
    } else {
      cleanAsins.push(`{asin:${JSON.stringify(asin.asin)}}`);
    }
  }
  const query = `mutation {
       addCategory(
          name:${JSON.stringify(name)}, 
          asins:[ ${cleanAsins.join(',')} ],
          addVariants: ${addVariants},
          tier: "${tier}"
         ) {
          ${categoryFragment}
        }
     }`;
  return secureGraphQLFetch({ query }).then((result) => result);
};

const addAsinsToCategory = ({ categoryId, asins, addVariants, tier }) => {
  // Clean up the asin array, since GraphQL doesn't like property keys
  // in quotes, which JSON.stringify will add
  const cleanAsins = [];
  for (let index = 0; index < asins.length; index += 1) {
    const asin = asins[index];
    if (asin.price) {
      cleanAsins.push(`{asin:${JSON.stringify(asin.asin)},price:${asin.price}}`);
    } else {
      cleanAsins.push(`{asin:${JSON.stringify(asin.asin)}}`);
    }
  }
  const query = `mutation {
       addAsinsToCategory(
         id:"${categoryId}", 
         asins:[ ${cleanAsins.join(',')} ],
         addVariants: ${addVariants},
         tier: "${tier}"
       )
     }`;
  return secureGraphQLFetch({ query }).then((result) => result);
};

const updateAccount = ({ id, name, contactName, contactPhone, brandRegistrationMember, amazonSeller, ownerOrAgent }) => {
  const query = `
  mutation {
    updateAccount(
      id:${id}
      name:${JSON.stringify(name) || null}
      contactName:${JSON.stringify(contactName) || null}
      contactPhone:${JSON.stringify(contactPhone) || null}
      brandRegistrationMember: ${brandRegistrationMember || false}
      amazonSeller: ${amazonSeller || false}
      ownerOrAgent: ${ownerOrAgent || false}
    )
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.account);
};

const createStripeCustomer = ({ stripeToken }) => {
  const query = `mutation {
    createStripeCustomer(stripeToken:${JSON.stringify(stripeToken)})
  }`;

  return secureGraphQLFetch({ query })
    .then((result) => result)
    .catch((err) => console.log(err));
};

const addCreditCard = ({ stripeCustomerId, stripeToken }) => {
  const query = `mutation {
    addCreditCard(stripeCustomerId:"${stripeCustomerId}", stripeToken:"${stripeToken}")
  }`;
  return secureGraphQLFetch({ query }).then((result) => result);
};

export default {
  getAccountById,
  getAsinNumbersForAccount,
  addAsinsToCategory,
  getCategory,
  getCategories,
  addCategory,
  updateAccount,
  createStripeCustomer,
  addCreditCard,
};
